.bg-achivement{
    background-color: #138ecc
    }
    
    .bg-achivement-cutomer-tier{
        background-color: #1A9FE0
    }
    
    .card-height-achievement{
     max-height: 230px;
     min-height: 230px;
     border: none;
    }
    
    .brand-name{
        font-family: Nunito !important;
        color: #133F8A !important;
        font-size: 1em !important;
    }
    
    .stripe-color{
        color: #7093ec;
    }
    
    .header-color{
        font-family: Nunito !important;
        color: #fafbfd !important;
        font-size: 1.2em !important;
        font-weight: 600 !important;
    }
    
    .heading-text {
        font-family: Nunito !important;
        font-size: 1.1em !important;
        font-weight: 900 !important;
        color: #f77700;
    }
    
    .heading-text-features {
        font-family: Nunito !important;
        font-size: 1.2em !important;
        font-weight: 800 !important;
        color: #f77700;
    }
    
    .heading-text-purchase-history{
        font-family: Nunito !important;
        font-size: 1.1em !important;
        font-weight: 800 !important;
        color: #f77700;
    }
    
    .brands-heading-text-purchase-history {
        font-family: Nunito !important;
        font-size: 1.1em !important;
        font-weight: 900 !important;
        color: #800080;
    }
    
    .heading-future-target{
        font-family: Nunito !important;
        font-size: 0.95em !important;
        font-weight: 700 !important;
        color: #3a333a;
    }
    
    .current-brand-tier{
        border-top-left-radius: 10px !important ;
        border-top-right-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
    }
    
    .heading-current-plan{
        font-size: 13px !important;
        color: white !important;
        font-weight: 0;
    }
    
    .customer-tier-heading{
        font-size: 22px !important;
        color: white !important;
        font-weight: bold;
        font-family: Nunito !important;
    }
    
    .customer-tier-plan{
        font-size: 16px !important;
        color: white !important;
        font-weight: bold;
        font-family: Nunito !important;
    }

    .tbodyDiv{
        max-height: clamp(12em,27vh,550px);
        max-width: 900px;
        height: 600px;
        overflow: auto;
    }
    
    @media only screen and (min-width: 900px) and (max-width: 1000px)  {
        .tbodyDiv{
            /* max-height: clamp(550px); */
            max-height: clamp(12em,38vh,550px);
            max-width: 900px;
            height: 600px;
            overflow: auto;
        }
        .current-brand-tier{
            border-top-left-radius: 10px !important ;
            border-top-right-radius: 10px !important;
            border-bottom-left-radius: 10px !important;
            border-bottom-right-radius: 10px !important;
        }
        
        .heading-current-plan{
            font-family: Nunito !important;
            color: #fafbfd !important;
            font-size: 1.2em !important;
            font-weight: 600 !important;
        }
        
        .customer-tier-heading{
            font-size: 22px !important;
            color: white !important;
            font-weight: bold;
        }
    }
    
    @media only screen and (min-width: 1092px) and (max-width: 1100px)  {
        .tbodyDiv{
            /* max-height: clamp(550px); */
            max-height: clamp(15em,53vh,500px);
            max-width: 900px;
            height: 600px;
            overflow: auto;
        }
    }
    
    @media only screen and (min-width: 1200px) and (max-width: 1300px)  {
        .tbodyDiv{
            /* max-height: clamp(550px); */
            max-height: clamp(12em,42vh,550px);
            max-width: 900px;
            height: 600px;
            overflow: auto;
        }
    }
    
    @media only screen and (min-width: 1300px) and (max-width: 1400px)  {
        .tbodyDiv{
            /* max-height: clamp(550px); */
            max-height: clamp(12em,35vh,550px);
            max-width: 900px;
            height: 600px;
            overflow: auto;
        }
    }
    
    @media only screen and (min-width: 1400px) and (max-width: 1500px)  {
        .tbodyDiv{
            /* max-height: clamp(550px); */
            max-height: clamp(12em,27vh,550px);
            max-width: 900px;
            height: 600px;
            overflow: auto;
        }
    }
    
    @media only screen and (min-width: 1500px) and (max-width: 1600px)  {
        .tbodyDiv{
            /* max-height: clamp(550px); */
            max-height: clamp(12em,28vh,550px);
            max-width: 900px;
            height: 600px;
            overflow: auto;
        }
    }
    
    @media only screen and (min-width: 1600px) and (max-width: 1700px)  {
        .tbodyDiv{
            /* max-height: clamp(550px); */
            max-height: clamp(12em,35vh,550px);
            max-width: 900px;
            height: 600px;
            overflow: auto;
        }
    }
    
    @media only screen and (min-width: 1700px) and (max-width: 1800px)  {
        .tbodyDiv{
            /* max-height: clamp(550px); */
        max-height: clamp(12em,25vh,550px);
            max-width: 900px;
            height: 600px;
            overflow: auto;
        /* max-width: auto;
        height: auto;
        overflow: auto; */
    }
    }
    
    @media only screen and (min-width: 1800px) and (max-width: 1900px)  {
        .tbodyDiv{
            /* max-height: clamp(550px); */
            max-height: clamp(12em,24vh,550px);
            max-width: 900px;
            height: 600px;
            overflow: auto;
        }
    }
    
    @media only screen and (min-width: 1900px) and (max-width: 2000px)  {
        .tbodyDiv{
            /* max-height: clamp(550px); */
            max-height: clamp(12em,27vh,550px);
            max-width: 900px;
            height: 600px;
            overflow: auto;
        }
    }
    
    @media only screen and (min-width: 2000px) and (max-width: 2100px)  {
        .tbodyDiv{
            /* max-height: clamp(550px); */
            max-height: clamp(12em,21vh,550px);
            max-width: 900px;
            height: 600px;
            overflow: auto;
        }
    }
    
    @media only screen and (min-width: 2100px) and (max-width: 2200px)  {
        .tbodyDiv{
            /* max-height: clamp(550px); */
            max-height: clamp(12em,27vh,550px);
            max-width: 900px;
            height: 600px;
            overflow: auto;
        }
    }
    
    @media only screen and (min-width: 2700px) and (max-width: 2800px)  {
        .tbodyDiv{
            /* max-height: clamp(550px); */
            max-height: clamp(12em,16vh,550px);
            max-width: 1200px;
            height: 600px;
            overflow: auto;
        }
    }
    
    .brand-names{
        font-family: Nunito !important;
        font-size: 1em !important;
        font-weight: 700 !important;
    }

    .font-achivement{
        font-family: Nunito !important;
    }

    .font-purchase-heading{
        font-family: Nunito !important;
        font-weight: 700 !important;
    }
    .width-achievement{
        min-width: 100% !important;
        min-height: 400;
    }
    .scroll-achiv{
        overflow: hidden !important;
    }
    .scroll-purchase{
        overflow: auto !important;
    }
    .cursor{
        cursor: text !important;
        background: white !important;
    }