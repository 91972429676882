
.reject-button {
    border-radius: 25px !important;
    background-color:  #ff0000 !important;
    color: #FFFFFF !important;
    text-transform: capitalize !important;
    padding-left: 28px !important;
    padding-right: 28px !important;
    height: 2.2rem;
    font-weight: 600 !important;
    font-family: Nunito !important;
    /* margin-top: 7px !important; */
}

.reject-meesage-warning {
    fill:  #ff0000 !important;
    background-color: white !important;
    border-radius: 50px !important;
    padding: 7px !important;
    font-size: 65px !important;
}
       
.textArea-correction{
    border-radius: 10px !important;
    border-color: teal;
    font-size: 15px !important;
    width: 400px;
    height: 120px;
    resize:none;
}

.submit-reject-button{
    border-radius: 25px !important;
    background-color: #ff0000 !important;
    color: #FFFFFF !important;
    text-transform: capitalize !important;
    padding-left: 28px !important;
    padding-right: 28px !important;
    height: 2.2rem;
    font-weight: 600 !important;
    font-family: Nunito !important;
    text-align: center;
    margin-right: 24px;
    width: 120,
}

.reject-dialog-font {
    font-weight: 800;
    color: #ff0000;
}

.history-reject-icon{
    color: #ff0000;
}

.info-sub-header-reject-text{
    color: #ff0000;
    font-size: 13px;
    font-family: Nunito !important;
    font-weight: 700 !important;
}
