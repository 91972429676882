.add-user-tabs {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 3px 6px;
    background-color: #1A9FE0;
    border: 1px solid #1A9FE0;
    color: #FFFFFF;
    font: 0.613rem Nunito;
    font-weight: 700;
}

.MuiBox-root {
    padding: 0px !important;
}

.MuiDialogTitle-root {
    flex: 0 0 auto;
    margin: 0;
    padding: 16px 24px 5px 24px !important;
}

.add-user-tabs-button {
    border-radius: 25px !important;
    background-color: #1A9FE0 !important;
    color: #FFFFFF !important;
    text-transform: capitalize !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
    height: 2.2rem;
    font-weight: 600 !important;
    font-family: Nunito !important;
    /* margin-top: 7px !important; */
}

.check-availability-button {
    border-radius: 25px !important;
    background-color: #1A9FE0 !important;
    color: #FFFFFF !important;
    text-transform: capitalize !important;
    padding-left: 28px !important;
    padding-right: 28px !important;
    height: 2.2rem;
    font-weight: 600 !important;
    font-family: Nunito !important;
    /* margin-top: 7px !important; */
}

.cancel-button {
    border-radius: 25px !important;
    background-color: #EAEAEA !important;
    color: #666666 !important;
    text-transform: capitalize !important;
    padding-left: 35px !important;
    padding-right: 35px !important;
    height: 2.4rem;
    font-weight: 700 !important;
    font-family: Nunito !important;
}

.access-parameter-field {
    color: #444444;
    /* font-size: 0.875rem; */
    font-weight: 700;
    font-family: Nunito;
}

.colorOfToggle{
    color: #ebe6e6;
    /* font-size: 0.875rem; */
    font-weight: 700;
    font-family: Nunito;
}

.disabled-tab {
    color: #666666 !important;
    font-weight: 700 !important;
    font-family: Nunito;
}

.lock-indicator {
    color: #666666 !important;
    font-family: Nunito !important;
    font-weight: 600 !important;
}

.MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track {
    background-color: #c2abab !important;
}

.MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
    background-color: #1A9FE0 !important;
}

.errorMsg{
    color: #ff0000;
    margin-top: 5px;
    margin-left: 10px;
    line-height: 16px;
    font-size: 12px;
    font-family: Nunito !important;
}

.successMessage{
    color: #006400;
    margin-top: 5px;
    margin-left: 10px;
    line-height: 16px;
    font-size: 12px;
    font-family: Nunito !important;
}