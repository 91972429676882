@import url('https://fonts.googleapis.com/css?family=Nunito:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i');
body {
    margin: 0;
    font-family: 'Nunito', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #666666 !important;
    font-size: .8125rem;
}

.MuiFormLabel-root,
.MuiButton-root,
.MuiTypography-root,
.MuiTableCell-root,
.MuiTab-root {
    font-family: 'Nunito', sans-serif !important;
    font-size: .8125rem !important;
}

.text-info-deactivationReason {
    color: #e0591a !important;
    font-size: 12px !important;
    font-size: 13px !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
    text-transform: capitalize !important;
}

/* .MuiInputBase-input{} */

.MuiFormLabel-root.Mui-focused {
    color: #1A9FE0 !important;
}

.MuiButton-root:hover {
    text-decoration: none;
    background: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(165, 163, 163, 0.459);
    box-shadow: 0 0 0px rgba(165, 163, 163, 0.459);
    border-radius: 10px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar {
    width: 10px !important;
    height: 10px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: 0 0 0px rgba(189, 182, 182, 0.445);
    -webkit-box-shadow: inset 0 0 0px rgba(189, 182, 182, 0.445);
    background-color: #1A9FE0;
}

.noselecttionZone {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently*/
}

.pointer-events-none {
    pointer-events: none
}

.font-10 {
    font-size: 0.625rem !important;
}

.font-11 {
    font-size: 0.6875rem !important;
}

.font-12 {
    font-size: 0.75rem !important
}

.font-13 {
    font-size: 0.8215rem !important;
}

.font-14 {
    font-size: 0.875rem;
}

.font-16 {
    font-size: 1rem;
}

.font-18 {
    font-size: 1.125rem
}

.font-20 {
    font-size: 1.25rem !important
}

.font-24 {
    font-size: 1.5rem
}

.font-30 {
    font-size: 1.875rem
}

.font-32 {
    font-size: 2rem
}

.font-48 {
    font-size: 3rem;
}

.font-72 {
    font-size: 4.5rem;
}

.ln-24 {
    line-height: 1.5rem;
}

.ln-20 {
    line-height: 1.25rem;
}

.ln-40 {
    line-height: 2.5rem;
}

.ln-52 {
    line-height: 3.25rem;
}

.ln-16 {
    line-height: 1rem;
}

.w-50p {
    width: 50px !important;
}

.w-60p {
    width: 60px !important;
}

.w-100p {
    width: 100px !important;
}

.w-150p {
    width: 150px !important;
}

.w-200p {
    width: 200px !important;
}

.text-red {
    color: #ff0000 !important;
}

.text-666 {
    color: #666666;
}

.text-444 {
    color: #444444;
}

.font-weight-700 {
    font-weight: 700 !important;
}

.font-weight-600 {
    font-weight: 600;
}

.vertical-align-top {
    vertical-align: top;
}

.mw-200p {
    min-width: 200px !important;
}

.mw-210p {
    min-width: 210px !important;
}

.mw-230p {
    min-width: 230px !important;
}

.mw-240p {
    min-width: 240px !important;
}

.mw-150p {
    min-width: 150px !important;
}

.mw-100p {
    min-width: 100px !important;
}

.hw-64 {
    height: 64px !important;
    width: 64px !important;
}

.h-40 {
    height: 40px;
}

.h-28 {
    height: 28px;
}

.hw-45 {
    height: 45px !important;
    width: 45px !important;
}

.text-fff {
    color: #fff;
}

.popup-modal-content {
    width: 300px !important;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.popup-title {
    height: 40px;
    position: absolute;
    width: 100%;
    left: 0px;
    background-color: whitesmoke;
    top: 0px;
    text-align: center;
    padding-top: 5px;
    color: black;
}

.popup-content {
    margin-top: 40px;
    padding-top: 10px;
    width: 100%;
    text-align: center;
}

.popup-modal {
    height: 70px;
}

.left-margin {
    margin-left: 10px;
}

.toast-wrapper {
    position: fixed;
    top: 60px;
    right: 30px;
    z-index: 100 !important;
}

.toast {
    opacity: 1 !important;
    width: 350px;
}

.text-bold-termsandconditions {
    color: '#616161';
    font-size: '0.875rem';
    font-family: 'Nunito';
    font-weight: 700;
    text-transform: 'inherit';
}

.toast-header {
    color: dodgerblue !important;
}

.card-bottom-list {
    max-height: 303px;
}

.bg-login-image {
    height: 200px;
    margin-bottom: 20px;
}

.bg-gradient-primary-green {
    background-image: linear-gradient(180deg, #2962ff 10%, #00c6b6 100%);
    background-size: cover;
}

.icon-green {
    color: seagreen;
}

.bg-custom-dark {
    background-color: #ffffff;
}

.dark-breadcrumb {
    background-color: #2962ff !important;
    margin-top: 15px !important;
}

li a {
    color: cadetblue;
}

.cadet {
    color: cadetblue;
}

.text-green {
    color: cadetblue !important;
    font-family: sans-serif !important;
}

.toggle-area {
    display: none;
}

.collapsed {
    display: block;
}

.toggle-button {
    background-color: #2962ff !important;
    border-color: #2962ff !important;
    width: 40px;
    color: seagreen !important;
}

.min-logo-7-11 {
    display: block;
    text-align: left;
    height: 50%;
    width: 60px;
}

.minisidebar .minisidebar-brand {
    text-decoration: none;
    font-size: 20px;
    font-weight: 800;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    background-color: var(--white);
}

.minisidebar .minisidebar-brand .minisidebar-brand-icon i {
    font-size: 2rem;
}

input[disabled] {
    background-color: rgba(255, 255, 255, 0.04);
}

.error-border-input {
    border: 2px solid red !important;
}

.center-alignment {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    align-items: center !important;
    justify-content: center !important;
}

.h-45 {
    height: 45px !important;
}

.remove-cursor-icon {
        cursor: not-allowed !important;
        cursor: none !important;
        pointer-events: none !important
}

/*Error Pages CSS*/

.expired-error {
    font-family: Nunito !important;
    color: #333333 !important;
    font-size: 150px !important;
    font-weight: 900 !important;
    text-shadow: 0 1px 0 #000000, 0 2px 0 #000000, 0 3px 0 #000000, 0 4px 0 #000000, 0 5px 0 #000000;
}

.user-already-registered-error {
    font-family: Nunito !important;
    color: #333333 !important;
    font-size: 150px !important;
    font-weight: 900 !important;
}

.expired-link-message {
    font-family: Nunito !important;
    color: #000000 !important;
    font-size: 25px !important;
    font-weight: 900 !important;
}

.expired-link-desc {
    font-family: Nunito !important;
    color: #000000 !important;
    font-size: 13px !important;
    font-weight: 700 !important;
}

.go-to-home-button {
    font-family: Nunito !important;
    text-transform: inherit !important;
    font-weight: 600 !important;
}

.click-entire-row {
    cursor: pointer;
}

@media (min-width: 384px) {
    .logo-7-11 {
        height: 28px;
        display: block;
        text-align: center;
        padding: 7px;
    }
    .logo-brand-text {
        font-size: 16px;
        font-weight: bold;
    }
}

@media (min-width: 576px) {
    .logo-7-11 {
        height: 30px;
        display: block;
        text-align: center;
        padding: 7px;
    }
    .logo-brand-text {
        font-size: 20px;
        font-weight: bold;
    }
}

@media (min-width: 768px) {
    .logo-7-11 {
        height: 41px;
        display: block;
        text-align: center;
        padding: 7px;
    }
    .logo-brand-text {
        font-size: 28px;
        font-weight: bold;
    }
}

.scrollTop {
    position: fixed;
    width: 100%;
    bottom: 20px;
    height: 20px;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.5;
}

.scrollTop:hover {
    opacity: 1;
}

.ScrollTopIcon {
    display: block;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.5;
    }
}

@media (max-width: 380px) {
    .collapsed {
        display: none !important;
    }
    .toggle-area {
        position: fixed;
        bottom: 2%;
        left: 2%;
        z-index: 200 !important;
        display: block;
    }
}

.pointer-none {
    pointer-events: none !important;
}

.flexone {
    flex: 1;
}

.row-space-between {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.row-space-start {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}

.row-space-end {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
}


/*common css*/


/*button theme*/

.MuiButton-root {
    padding: 6px 24px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
}

.MuiButton-contained {
    box-shadow: none !important;
    text-transform: capitalize !important;
}

.MuiButton-containedPrimary {
    background-color: #1a9fe0 !important;
}

.MuiButton-containedPrimary:hover {
    background-color: #1297d8 !important;
}

.MuiButton-containedPrimary.Mui-disabled {
    color: #fff !important;
    opacity: 0.65;
}

.btn-link,
.btn-link:hover {
    padding: 0 !important;
    background-color: transparent !important;
}

.btn-link .MuiTouchRipple-root {
    display: none;
}


/*dropdown change*/

.MuiMenuItem-root,
.MuiInputBase-root {
    font-size: 0.8125rem !important;
    font-family: 'Nunito', sans-serif !important;
}

.MuiSelect-select:focus {
    background-color: transparent !important;
    border-radius: 500px !important;
}


/*mat tab css*/

.MuiTab-root {
    text-transform: capitalize !important;
    font-weight: 500 !important;
}

.MuiTab-textColorPrimary {
    color: #666 !important;
}

.MuiTab-textColorPrimary.Mui-selected {
    color: #1A9FE0 !important;
    font-weight: 900 !important;
}

.MuiTabs-indicator {
    background-color: #1A9FE0 !important;
    height: 5px !important;
    border-radius: 10px;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #1A9FE0 !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
    color: #1A9FE0 !important;
}

.small-check .MuiSvgIcon-fontSizeSmall {
    font-size: 14px !important;
}

.cal-icon .MuiIconButton-root {
    padding: 0 !important;
}


/*table*/

.MuiTableCell-root {
    font-size: 0.75rem !important;
    /* white-space: nowrap; */
}


.enquiryDetails .MuiTableCell-root {
    white-space: nowrap;
} 

.enquiryDetails .MuiCheckbox-root {
    padding: 0px !important;
} 

.enquiryDetails .MuiTableCell-root.font-10 {
    white-space: nowrap;
    font-size: 0.625rem !important;
} 

.bg-transparent {
    background-color: transparent !important;
}


/*left nav*/

.left-nav .MuiSvgIcon-root {
    height: 1.3rem;
    width: 1.3rem;
}

.MuiListItem-button:hover {
    background-color: transparent !important;
}

.MuiListItem-button:hover .MuiTypography-body1 {
    font-weight: 600;
}

.MuiListItem-button:hover::before,
.MuiListItem-button.active-menu::before {
    content: " ";
    position: absolute;
    background-color: #666;
    width: 6px;
    height: 100%;
    left: -16px/*coz of padding to parent*/
    ;
}

.left-nav .MuiTouchRipple-root {
    display: none;
}

.MuiListItem-button.active-menu,
.MuiListItem-button.active-menu .MuiListItemIcon-root {
    color: #1A9FE0 !important;
}

.MuiListItem-button.active-menu::before {
    background-color: #1A9FE0;
}

.MuiListItem-button.active-menu .MuiTypography-body1 {
    font-weight: 800;
}


/*md dialog size*/

.MuiDialog-paperWidthMd {
    max-width: 800px !important;
}

.MuiDialog-paper {
    overflow-x: hidden;
}

/*utility*/

.mh-300p {
    min-height: 300px;
}

.page-title,
.page-sub-title {
    font-weight: 800;
    font-size: 1.5rem;
    color: #000000;
}

.page-sub-title-set-target {
    font-weight: 800;
    font-size: 0.75rem;
    color: #000000;
}

.page-sub-title {
    font-size: 0.875rem;
    margin-bottom: 6px;
}

.MuiListItem-gutters {
    padding-left: 12px;
    padding-right: 12px;
}

.date-picker-50 .DateInput {
    width: 45% !important;
}

.copyright {
    position: absolute;
    bottom: 12px;
}


/* theme*/

.bg-primary {
    background-color: #1A9FE0 !important;
}

.MuiPickersDay-daySelected {
    background-color: #1A9FE0 !important;
}

.MuiAlert-root {
    border-radius: 100px !important;
}

.MuiBreadcrumbs-separator {
    margin-left: 3px !important;
    margin-right: 3px !important;
}

@media (max-height: 790px) {
    .copyright {
        position: relative;
        bottom: auto
    }
}


/* Medium large devices (1200px to 1399 px)*/

@media (min-width: 1200px) and (max-width: 1424px) {
    .col-ml-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }
    .col-ml-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.666667;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-ml-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-ml-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-ml-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
    .col-ml-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-ml-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
    .col-ml-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
    .col-ml-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-ml-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
    .col-ml-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }
    .col-ml-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .MuiButton-root {
        padding: 6px 18px !important;
    }
}

.lostSaleReport 
    .MuiOutlinedInput-input {
        padding: 12.5px 14px;
    }

.dealer-custom-dropdown {
    font-family: "Nunito" !important;
    height: "200px" !important;
    overflow-y: "scroll" !important;
}

.searchDealer {
    background-color: #FAFAFA !important;
    font-size: 0.725rem !important;
    font-family: Nunito !important;
    color: #999999 !important;
    font-weight: 600 !important;
}

.approve-warning {
    fill: #1a9fe0 !important;
    background-color: white !important;
    border-radius: 50px !important;
    padding: 1px !important;
    font-size: 50px !important;
}

.searchDealer .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.onboard-footer {
    display: flex !important;
      justify-content: end;
  }
  
  .display-flex {
    display: flex !important;
  }

.defaultAddressButton {
    border-radius: 2.5rem !important;
    background-color: #666666 !important;
    font-weight: 600 !important;
    text-transform: inherit !important;
    font-family: Nunito !important;
    font-size: 0.625rem;
    min-width: 100%;
    /* height: 30px; */
}

.brandbutton {
    border-radius: 2.5rem !important;
    background-color: #666666 !important;
    font-weight: 600 !important;
    text-transform: inherit !important;
    font-family: Nunito !important;
    font-size: 0.625rem;
    min-width: 50%;
    height: 25px;
}

.creditAmountButton{
    border-radius: 2.5rem !important;
    font-weight: 600 !important;
    text-transform: inherit !important;
    font-family: Nunito !important;
    font-size: 0.625rem;
    min-width: 50%;
    height: 25px;
    background-color: #eef3f5 !important;
    color:#1A9FE0 !important;
}

.creditAmountButtonKAM{
    border-radius: 2.5rem !important;
    font-weight: 600 !important;
    text-transform: inherit !important;
    font-family: Nunito !important;
    font-size: 0.625rem;
    min-width: 50%;
    height: 25px;
    background-color: #eef3f5 !important;
    color:black !important;
}

.subTitleKamCredit {
    color: #666666;
    font-family: Nunito;
    font-size: 0.79rem;
    font-weight: 700;
}

.underKAMReviewAddressButton {
    border-radius: 2.5rem !important;
    background-color: #f30a0a !important;
    font-weight: 600 !important;
    text-transform: inherit !important;
    font-family: Nunito !important;
    font-size: 0.625rem;
    min-width: 100%;
    /* height: 30px; */
}

.defaultStatusButton {
    border-radius: 2.5rem !important;
    background-color: #666666 !important;
    font-weight: 600 !important;
    text-transform: inherit !important;
    font-family: Nunito !important;
    font-size: 0.625rem;
    min-width: 100%;
}

.hiddenNo input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.hiddenNo input[type=number] {
    -moz-appearance: textfield;
}

.onshiftoparty-font {
    font-weight: 800;
}

.shift-to-party-page-title{
    font-weight: 800;
    font-size: 1.75rem;
    color: #000000;
}
.newCustomerDialogTitle{
    font-weight: 800;
    font-size: 1.75rem;
    color: #000000;
}
.document-content{
    color: #666666;
    opacity: 1;
    font-size: 0.90rem !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
}
.submit-dialog-content{
    color: #666666;
    opacity: 1;
    font-size: 1.3rem !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
}
.customerDialog{
    color: #666666;
    opacity: 0.5;
    font-size: 0.90rem !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
}
.info-sub-header-document {
    color: #1A9FE0;
    font-size: 13px;
    font-family: Nunito !important;
    font-weight: 700 !important;
}
.document-sub-content{
    color: #666666;
    opacity: 1;
    font-size: 0.75rem !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
}

.document-sub-content-add{
    color: #666666;
    opacity: 1;
    font-size: 0.72rem !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
}

.document-sub-content-additional{
    color: #666666;
    opacity: 1;
    font-size: 0.75rem !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
}

.document-border-style{
    padding:0,
}
.document-tablecell-data{
    color: #666666;
    opacity: 1;
    font-size: 0.88rem !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
}

.whiteBackdropDialog .MuiBackdrop-root{
    background-color: white !important;
}

.addAuthorised {
    border-radius: 2.5rem !important;
    background-color: #1A9FE0 !important;
    font-weight: 600 !important;
    width: 600px;
    text-transform: inherit !important;
    font-family: Nunito !important;
    font-size: 0.625rem;
    min-height: 35px;
}

.typeDialogParent .MuiBackdrop-root{
    background: white !important;
}

.marginScrollableArea{
    margin-bottom: 100px !important;
}

.yellowActionButton{
    background-color: #e88b00 !important;
    color:white !important;
}

.checkIcon-setTarget{
    fill: #1a9fe0 !important;
    background-color: white !important;
    border-radius: 50px !important;
    padding: 7px !important;
    font-size: 65px !important;
}

.title-set-target-dialog{
    color: #000000;
    font-size: 20px !important;
    border-radius: 1rem !important;
    font-family: Nunito !important;
    font-size: 0.625rem;
}

.resetToDefaultActionButton{
    background-color: #1a9fe0 !important;
    color:white !important;
}

.redActionButton{
    background-color: #ec4242 !important;
    color:white !important;
}
.blueActionButton{
    background-color: #1a9fe0 !important;
    color:white !important;
}

.blueActionButton-superAdmin{
    background-color: #1a9fe0 !important;
    color:white !important;
    width: 130px;
    height: 150 px;
}

.blueActionButtonSave{
    background-color: #1a9fe0 !important;
    color:white !important;
    width: 150px;
}

.greenActionButton{
    background-color: #15a038 !important;
    color:white !important;
}

.disabledButtonCommon{
    opacity: 0.5 !important;
    pointer-events: none !important;
}

.subTitleRetrieveDialogContent {
    color: #666666;
    font-family: Nunito;
    font-size: 0.85rem;
    font-weight: 700;
}

.setTarget-monthly-target {
        color:#ffa500 !important;
        font-family: Nunito;
        font-size: 0.75rem;
        font-weight: 700;
}

.setTarget-quarter-target {
    color: #1A9FE0 !important;
    font-family: Nunito;
    font-size: 0.75rem;
    font-weight: 700;
}

.setTarget-yearly-target {
    color: #0cd316 !important;
    font-family: Nunito;
    font-size: 0.75rem;
    font-weight: 700;
}

.info-sub-title-value-default{
    color: #666666;
    font-family: Nunito;
    font-size: 0.75rem;
    font-weight: 700;
}

.smalldialogadd .MuiPaper-root{
    max-width: 500px;
    min-width: 500px;
}

.file-name-kam {
    color: #1A9FE0 !important;
    opacity: 1;
    font-size: 0.70rem !important;
    font-weight: 600 !important;
    font-family: Nunito !important;
}

.file-close-color{
    color: #1A9FE0 !important;
}

.dollar-kam-dealerList{
    color: #1A9FE0 !important;
}

.dollar-icon-color{
    color: #1A9FE0 !important;
}

.creditLimit-dealerDetails {
    color: #1A9FE0 !important;
    font-weight: 700;
    font-size: 11px;
    font-family: Nunito;
}

.salestoCustomerKam  .MuiSelect-outlined.MuiSelect-outlined{
    padding-right: 0 !important;
}

.new-brand-credit-request-button {
    border-radius: 2.5rem !important;
    background-color: #1A9FE0 !important;
    font-weight: 400 !important;
    text-transform: inherit !important;
    font-family: Nunito !important;
    font-size: 0.625rem;
    height: 35px;
}
#nationality .MuiPopover-paper {
    height: 350px !important
}
.DateRangePickerInput__withBorder, .MuiOutlinedInput-notchedOutline {
    border: 2px solid black !important;
}

.Font-changes{
font-size: 25px;
font-weight: 800;
font-size: 1.5rem;
}

.DateInput_input__focused{
    border-bottom: 2px solid #1A9FE0 !important;
}

.MuiIconButton-root:hover {
    background: none;
}
  
@media only screen and (min-width: 1024px) and (max-width: 1270px)  {
    .aed-width {
        width: 50px !important
    }
}

@media only screen and (min-width: 1270px) and (max-width: 2100px)  {
    .aed-width {
        width: 70px !important
    }
}
  

@media only screen and (min-width: 650px) and (max-width: 860px)  {
    .aed-width {
        width: 40px !important
    }
    .turnover-width {
        width: 60px !important;
    }
}


@media only screen and (min-width: 860px) and (max-width: 1024px)  {
    .aed-width {
        width: 40px !important
    }
    .turnover-width {
        width: 100px !important;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1220px)  {
    .aed-width {
        width: 50px !important
    }
    .turnover-width {
        width: 150px !important;
    }
}

@media only screen and (min-width: 1220px) and (max-width: 1300px)  {
    .aed-width {
        width: 60px !important
    }
    .turnover-width {
        width: 200px !important;
    }
}

@media only screen and (min-width: 1300px) and (max-width: 2100px)  {
    .aed-width {
        width: 70px !important
    }
    .turnover-width {
        width: 280px !important;
    }
}
  
@media only screen and (min-width: 860px) and (max-width: 1270px)  {
    .aed1-width {
        width: 70px !important
    }
}

@media only screen and (min-width: 1270px) and (max-width: 2100px)  {
    .aed1-width {
        width: 400px !important
    }
}

.ml-280 {
    margin-left: 280px !important;
}
.ml-70 {
    margin-left: 70px !important;
}


.max-width-80{
    max-width: 80%;
    height: auto;
}
.cursor-pointer{
    cursor: pointer !important;
}
.feature-chip{
    border: 1px solid #bdbdbd !important;
    border-radius: 16px !important;
    font-family: Nunito !important;
    font-weight: 400;
}
.font-planfeatures{
    font-family: Nunito !important;
    font-weight: 400;
}

.edit-font{
    font-family: Nunito !important;
}
.fontweight-superadmin-header{
    font-weight: 900;
    font-size: 1.8rem !important;
}
.fontweight-superadmin{
    font-weight: 700;
}
.fontweight-superadmin-supheader{
    font-weight: 600;
}
.fontweight-superadmin-label{
    font-weight: 600 !important;
}
.font-update-superadmin{
    font-size: 1.1rem !important;
    padding: 13px !important;
    font-weight: 1000 !important;

}
.MuiPaper-elevation8 {
    border: none !important;
    box-shadow:rgba(0,0,0,0.24)0px 0x 0rem 0rem!important; ;
    /* 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%) */
}

.servicesImages{
    width: 72px;
    height: 50px
}

.maxw150{
    max-width: 150px;
}

.slider-action .container{
    display: none !important;
}

.color_black{
    color: black !important;
}