.importExcel {
    font-weight: 700;
    font-family: Nunito;
    font-size: 0.875rem;
    color: #1a9FE0 Sec;
}

.listIcon {
    font-weight: 700;
    color: #1a9FE0 Sec;
    padding: 5px !important;
}

.searchResults {
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    padding: 8px;
    flex-grow: 1;
    font-weight: inherit;
    cursor: pointer;
}

.validation-error {
    color: red;
    text-align: center;
}

.customDivs {
    border-radius: 2.5rem !important;
    background-color: #FAFAFA !important;
    color: #999999 !important;
    font-size: 14px !important;
    font-family: Nunito !important;
    font-weight: 500 !important;
    line-height: 0px;
    padding: 17px !important;
    border: 1px solid rgba(19, 63, 138, 0.15);
    min-width: 47%;
    min-height: 35px;
    font-family: Nunito;
}

.customDivs2 {
    border-radius: 2.5rem !important;
    background-color: #FAFAFA !important;
    color: #999999 !important;
    font-size: 14px !important;
    font-family: Nunito !important;
    font-weight: 500 !important;
    line-height: 0px;
    padding: 5px 15px 5px 15px !important;
    border: 1px solid rgba(19, 63, 138, 0.15);
    min-width: 47%;
    min-height: 35px;
}

.customTextField {
    background-color: #FAFAFA !important;
    color: #999999 !important;
    padding: 0px !important;
    margin-bottom: -1 !important;
    font-size: xx-small !important;
}

.searchResultsSelected {
    font-size: 0.875rem;
    font-family: Nunito;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    padding: 8px;
    flex-grow: 1;
    font-weight: inherit;
    cursor: pointer;
    background-color: #efeded;
    font-weight: 700
}

.searchbarDashboard {
    background-color: #ffffff;
    min-height: 5rem;
    display: flex;
    align-items: center;
}


/* .Dashboard {
    background-color: #ffffff;
    min-height: 20rem;
} */

.searchbarOptions .MuiIconButton-root {
    color: #1A9FE0 !important;
}

.addOrderEnqButton {
    border-radius: 2.5rem !important;
    background-color: #1A9FE0 !important;
    font-weight: 600 !important;
    text-transform: inherit !important;
    font-family: Nunito !important;
    font-size: 0.625rem;
    min-width: 100%;
}

.selReportTypeButton {
    border-radius: 2.5rem !important;
    color: #999999;
    font-weight: 600 !important;
    text-transform: inherit !important;
    font-family: Nunito !important;
    font-size: small;
}

.bodyContent {
    color: #666666 !important;
    font-weight: 600 !important;
    font-size: 0.75rem !important;
    font-family: Nunito !important;
}

.tableHead {
    color: #133F8A;
    font-weight: 900;
    font-size: 12px;
    font-family: 'Nunito';
    padding-top: 0px !important;
}

.MuiTableCell-head {
    line-height: 1rem !important;
}

.vat {
    color: #999999;
    font-size: 2rem;
    font-family: Nunito;
    font-weight: 700;
}

.smallText {
    color: #999999;
    font-size: 11px;
    font-family: Nunito !important;
    font-weight: 600;
}

.roundedCornerDiv {
    background-color: white;
    display: inline-block;
    border-radius: 20px;
    border: 1px solid #999999;
    height: 40px;
    min-width: 150px;
    padding-top: 0;
    margin-top: 0;
    font-size: small;
    padding: 8px 15px !important;
    font-family: Nunito;
    font-weight: 600;
}

.footDate {
    color: #666666;
    font-size: 0.813rem;
    font-weight: 600;
    font-family: Nunito;
}

.footOffers {
    color: #444444;
    font-weight: 600;
    font-family: Nunito;
    font-size: 0.875rem;
}

.footFav {
    color: #444444;
    font-weight: 700;
    font-family: Nunito;
    font-size: 0.875rem;
}

.report1 {
    color: #1A9FE0;
    font-weight: 800;
    font-family: Nunito;
    font-size: 0.875rem;
    text-transform: none;
}

.report2 {
    color: #666666;
    font-weight: 500;
    font-family: Nunito;
    font-size: 0.875rem;
}

.searchFav {
    background-color: #FAFAFA;
    font-size: 0.813rem;
    font-family: Nunito;
    color: #999999;
    font-weight: 600;
}

.dropdown {
    min-width: 100% !important;
    font-size: large;
}

.lossReasonDropdown {
    text-transform: none;
    min-width: 100% !important;
    font-family: Nunito;
    font-size: small;
    color: #999999;
}

.roundedCorners {
    border-radius: 5px;
}

.brandDropdown {
    text-transform: none;
    min-width: 100% !important;
    font-family: Nunito;
    font-size: small;
    color: #999999;
}