.full-width {
    width: 100%;
}

.infoCard {
    min-height: 40rem;
    max-height: 40rem;
}

.rounded-input .MuiOutlinedInput-root {
    border-radius: 2.5rem !important;
    font-size: 13px !important;
    font-family: Nunito !important;
    font-weight: 600 !important;
    border-color: rgba(19, 63, 138, 0.15) !important;
}

.rounded-button {
    border-radius: 25px !important;
    background-color: #1A9FE0 !important;
    color: #ffff !important;
    text-transform: capitalize !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
    font-family: Nunito !important;
    font-weight: 500 !important;
    height: 2.2rem !important;
}

.pull-right {
    float: right;
}



.font-13-bold {
    font-size: 0.813rem;
    font-weight: 800;
    font-family: Nunito;
    color: #444444;
}

.notification-pull-right {
    position: absolute;
    right: 0px;
}

.brand-name {
    color: #1A9FE0;
    font-family: Nunito !important;
    font-size: 0.875rem;
    font-weight: 700 !important;
}

.changePassword {
    color: #1A9FE0 !important;
    font-size: 0.75rem;
    font-weight: 700;
    font-family: Nunito !important;
    text-decoration: underline !important;
}