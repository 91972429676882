.height-400 {
    height: 400px;
    overflow: auto;
}

.height-500 {
    min-height: 500px;
}

.text-ellipsis {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    font-family: Nunito !important;
}

/* .section-title {
    color: #1A9FE0;
    font-size: 0.875rem;
    font-weight: 700;
    text-decoration: underline;
    font-family: Nunito;
} */

.section-content {
    color: #444444 !important;
    font-size: 0.75rem !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
}

.section-date {
    color: #666666;
    font-size: 0.688rem;
    font-weight: 700;
    font-family: Nunito;
}

.notification-pull-right {
    position: absolute;
    right: 0px;
}

.announce-sub-title {
    color: #000000;
    font-family: Nunito !important;
    font-weight: 800 !important;
    font-size: 1.063rem !important;
}

.announce-date {
    color: #666666 !important;
    font-size: 0.6875rem !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
}

.announce-content {
    color: #444444 !important;
    font-size: 0.813rem !important;
    font-weight: 600 !important;
    font-family: Nunito !important;
}

.MuiTypography-gutterBottom {
    margin-bottom: 0.05em !important;
}

.announce-details {
    color: #666666 !important;
    font-size: 0.875rem !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
}

.announce-not-available {
    color: #133F8A !important;
    font-size: 0.875rem !important;
    font-weight: 800 !important;
    font-family: Nunito !important;
}