.height-400 {
    height: 400px;
}

.height-500 {
    min-height: 400px;
    border-radius: 10px !important;
}

.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


/*     
    .section-title {
        color: #1A9FE0;
        font-size: 0.875rem;
        font-weight: 700;
        text-decoration: underline;
        font-family: Nunito;
    } */

.section-content {
    color: #444444 !important;
    font-size: 0.75rem !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
}

.section-date {
    color: #666666;
    font-size: 0.688rem;
    font-weight: 700;
    font-family: Nunito;
}

.bottom-link {
    /* position: absolute; */
    bottom: 0;
    text-decoration: underline;
    color: #1A9FE0;
    font-weight: 700;
}

.notification-pull-right {
    position: absolute;
    right: 0px;
}

a:hover {
    color: #1A9FE0;
    text-decoration: underline;
}

.special-date {
    color: #666666 !important;
    font-size: 0.813rem !important;
    font-weight: 500 !important;
    font-family: Nunito !important;
}

.special-content {
    color: #444444 !important;
    font-size: 0.875 !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
}

.special-tc {
    color: #444444 !important;
    font-size: 0.813rem !important;
    font-weight: 800 !important;
    font-family: Nunito !important;
}

.special-conditions {
    color: #444444 !important;
    font-size: 0.75rem !important;
    font-weight: 600 !important;
    font-family: Nunito !important;
}

.font-weight-bold {
    font-weight: bold !important;
}

.img-fluid-costom {
    max-width: 100%;
    height: 370px;
    border-radius: 10px;
    cursor: pointer;
}

.offers-top-section {
    width: 100% !important;
    height: 180px !important;
    transform: scale(1);
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    /* background-image: url("https://images.caricos.com/b/bmw/2016_bmw_m2/images/1600x1200/2016_bmw_m2_50_1600x1200.jpg") !important; */
    background-repeat: no-repeat;
    background-position: 0% 0% !important;
    /* background-attachment: fixed; */
}

.offers-top-section::before {
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    top: 0;
    left: 0;
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
}

.offers-top-content {
    color: #FFFFFF;
    font-size: 1.5rem;
    font-weight: 700;
    font-family: Nunito !important;
}

.offers-top-sub-content {
    color: #FFFFFF;
    font-size: 0.875rem;
    font-weight: 600;
    font-family: Nunito !important;
}

.offers-part-listing {
    color: #444444;
    font-family: Nunito !important;
    font-weight: 700;
    font-size: 0.813rem;
}

.click-part-copy {
    color: #666666;
    font-family: Nunito !important;
    font-weight: 600;
    font-size: 0.563rem;
}

.currency {
    color: #444444;
    font-family: Nunito !important;
    font-weight: 700;
    font-size: 0.688rem;
}

.download-offer {
    color: #1A9FE0;
    font-weight: 700 !important;
    font-size: 0.75rem !important;
    font-family: Nunito !important;
}

.download-icon {
    color: #1A9FE0;
    font-weight: 700 !important;
    font-size: 1.188rem !important;
}

.border-bottom {
    border-bottom: rgba(112, 112, 112, 0.2) 1px solid;
}

.rounded-circle {
    height: auto;
    width: 90px;
    border-radius: 100px;
}

.img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dddfeb;
    border-radius: 0.35rem;
    max-width: 70%;
    height: auto;
}

.container {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.special-offers-button {
    background-color: #FFFFFF !important;
    border-radius: 2.5rem !important;
    color: #666666 !important;
    font-size: 0.688rem !important;
    padding: 3px 27px !important;
    text-transform: capitalize !important;
    font-family: Nunito !important;
    font-weight: 700 !important;
    pointer-events: none;
}

.special-offers-button .MuiButton-label{
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    overflow: hidden;
}


.header {
    font-size: 0.6rem !important;
    font-family: Nunito !important;
    color: #FFFFFF !important;
    font-weight: 600 !important;
    background-color: #1A9FE0 !important;
    padding: 3px !important;
    /* min-width: 52px !important; */
    text-align: center !important;
}

.first-row {
    font-size: 0.538rem !important;
    font-family: Nunito !important;
    color: #444444 !important;
    font-weight: 700 !important;
    background-color: rgba(26, 159, 224, 0.5)!important;
    padding: 3px !important;
    text-align: center !important;
}

.second-row {
    font-size: 0.538rem !important;
    font-family: Nunito !important;
    color: #444444 !important;
    font-weight: 700 !important;
    background-color: rgba(26, 159, 224, 0.20)!important;
    padding: 7px 10px !important;
    text-align: center !important;
}

.underline-part {
    text-decoration: underline #444444;
}

.image-container {
    display: grid;
    place-items: center;
    min-height: 126px !important;
}

