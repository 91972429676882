.createFavoriteDialog .MuiDialog-paperWidthMd {
    max-width: 765px !important;
}

.createFavourite {
    border-radius: 2.5rem !important;
    background-color: #1A9FE0 !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
    font-family: Nunito !important;
    color: #FFFFFF !important;
    font-size: 11px !important;
    padding: 7px 28px !important;
}

.deleteButton {
    border-radius: 2.5rem !important;
    background-color: #1A9FE0 !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
    font-family: Nunito !important;
    color: #FFFFFF !important;
    font-size: 11px !important;
    padding: 7px 30px !important;
}

.downloadButton {
    border-radius: 2.5rem !important;
    background-color: #1A9FE0 !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
    font-family: Nunito !important;
    color: #FFFFFF !important;
    font-size: 11px !important;
    padding: 7px 25px!important;
}

/* .checkbox {
    padding-left: 0px !important;
    padding-right: 5px !important;
    border-radius: 15px;
    border: 2px solid rgba(112, 112, 112, 1);
} */

.MuiCheckbox-colorSecondary.Mui-checked {
    color: #1A9FE0 !important;
}

.MuiButtonGroup-groupedTextPrimary:not(:last-child) {
    border: none !important;
}

.errorMsg{
    font-size: 10px;
    line-height: 16px;
    color: #FF0000;
    align-items: left;
    font-style: Nunito !important;
    margin-left: 0 !important;

}
