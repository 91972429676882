.searchbarDashboard {
    background-color: #ffffff;
    min-height: 5rem;
    display: flex;
    align-items: center;
}


/* .Dashboard {
    background-color: #ffffff;
    min-height: 20rem;
} */

.MuiOutlinedInput-input {
    padding: 10.5px 14px;
}

.statuslabel {
    padding-top: 2px;
}

.checkbox {
    padding-left: 0px !important;
    padding-right: 5px !important;
    border-radius: 5px;
    border: 2px solid rgba(112, 112, 112, 1);
}

.myOrders {
    color: #444444;
    font-family: Nunito;
    font-weight: 600;
    font-size: 0.813rem;
    margin-top: 12px;
}

.legendItem {
    font-size: 0.75rem;
    font-weight: 600;
}

.legendItem .legendItemLabel {
    margin-left: 4px;
}

.dot {
    height: 9px;
    width: 9px;
    background-color: #1A9FE0;
    border-radius: 50%;
    display: inline-block;
}

.orangedot {
    height: 9px;
    width: 9px;
    background-color: #E88B00;
    border-radius: 50%;
    display: inline-block;
}

.totalHead {
    color: #133F8A;
    font-weight: 900;
    font-family: Nunito;
    width: 140px;
    font-size: 0.75rem;
}

.estimatedTotal {
    color: #444444;
    font-weight: 800;
    min-width: 140px;
    font-size: 0.75rem;
    font-family: Nunito;
}

.hover-pointer {
    cursor: pointer;
}