.leftMenuGlobal {
    float: left;
    width: 15%;
    min-width: 280px;
    max-width: 90% !important;
    min-height: 100vh;
}

.leftMenuGlobal .MuiDrawer-paper {
    float: left;
    width: 15%;
    min-width: 280px;
    max-width: 90%;
    border: none !important;
    box-shadow: 0 0rem 2rem rgba(0, 0, 0, .2);
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
    .leftMenuGlobal {
        min-width: 100% !important;
        max-width: 100% !important;
    }
    .leftMenuGlobal .MuiDrawer-paper {
        min-width: 100% !important;
        max-width: 100% !important;
    }
}

.collasped-menu {
    float: left;
    width: 4%;
    min-width: 70px;
    max-width: 90% !important;
    min-height: 100vh;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
    .collasped-menu {
        min-width: 100% !important;
        max-width: 100% !important;
    }
    .collasped-menu .MuiDrawer-paper {
        min-width: 100% !important;
        max-width: 100% !important;
    }
}

.companyDetailsSidebar {
    background-color: #1a9fe0;
}

.companyDetailsSidebarbottom {
    background-color: #0f95d6;
}

.companyNameSidebar {
    color: white;
    font-weight: 600;
    margin: 0;
    padding: 0;
    font-size: 0.813rem;
    font-family: Nunito !important;
}

.clientNameSidebar {
    color: white;
    font-weight: 200;
    margin: 0;
    padding: 0;
    font-size: 0.813rem;
    font-family: Nunito !important;
}

.companyLocationSidebar {
    color: white;
    font-weight: 200;
    margin: 0;
    padding: 0;
    font-size: 0.813rem;
    font-family: Nunito !important;
}

.MuiListItemIcon-root {
    min-width: 40px !important;
}

.logo-wrapper {
    position: relative;
}

.logo-position {
    margin: 0px;
    top: 50%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    position: absolute;
}