div.overlayLoader {
    display:table;
    background-color: black;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10000;
    top: 0px;
    left: 0px;
    opacity: .5; /* in FireFox */ 
    filter: alpha(opacity=50); /* in IE */
}
div.overlayLoader > div {
    display:table-cell;
    width:100%;
    height:100%;
    text-align: center;
    vertical-align: middle;
    z-index: 9999;
    pointer-events: none;
}
