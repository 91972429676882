.full-width{
    width: 100%;
}
.infoCard{
    min-height: 32rem;
}
.rounded-input .MuiOutlinedInput-root{
    border-radius: 2.5rem !important;
  }
.rounded-button{
    border-radius: 25px !important;
    background-color: #1A9FE0 !important;
    color: #ffff !important;
    text-transform: capitalize !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
    font-family: Nunito;
}
.pull-right{
    float: right;
}

.font-13-bold{
    font-size: 0.813rem;
    font-weight: 800;
    font-family: Nunito;
    color: #444444;
}
.notification-pull-right{
    position: absolute;
    right: 0px;
}