.brandbadgecreditRequest{
    width: 70px;
    height: 30px;
    border-radius: 30px;
    background-color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: 10px;
}
.diabledSectionCredit{
    pointer-events: none;
    opacity: 0.7;
}