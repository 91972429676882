.order-placed-tick-access-tab {
    color: #1b39e2;
    font-weight: 800;
    margin-bottom: 6px !important;
}

.check-availability-button-access-tab {
    border-radius: 25px !important;
    background-color: #1A9FE0 !important;
    color: #FFFFFF !important;
    text-transform: capitalize !important;
    padding-left: 28px !important;
    padding-right: 28px !important;
    height: 2.2rem;
    font-weight: 600 !important;
    font-family: Nunito !important;
    /* margin-top: 7px !important; */
}
.request-correction-button {
    border-radius: 25px !important;
    background-color: #ffa500 !important;
    color: #FFFFFF !important;
    text-transform: capitalize !important;
    padding-left: 28px !important;
    padding-right: 28px !important;
    height: 2.2rem;
    font-weight: 600 !important;
    font-family: Nunito !important;
    /* margin-top: 7px !important; */
}
.correction-meesage-warning {
    fill: #ffa500 !important;
    background-color: white !important;
    border-radius: 50px !important;
    padding: 7px !important;
    font-size: 65px !important;
}
       
.textArea-correction{
    border-radius: 10px !important;
    border-color: teal;
    font-size: 15px !important;
    width: 400px;
    height: 120px;
    resize:none;
}
.textArea-additionalCreditRequest{
    border-radius: 10px !important;
    border-color: teal;
    font-size: 15px !important;
}

.submit-correction-button{
    border-radius: 25px !important;
    background-color: #ffa500 !important;
    color: #FFFFFF !important;
    text-transform: capitalize !important;
    padding-left: 28px !important;
    padding-right: 28px !important;
    height: 2.2rem;
    font-weight: 600 !important;
    font-family: Nunito !important;
}

.onAccess-font {
    font-weight: 800;
    color:#ffa500;
}

.history-icon{
    color:#ffa500;
}

.info-sub-header-document-text{
    color:#ffa500;
    font-size: 13px;
    font-family: Nunito !important;
    font-weight: 700 !important;
}

.toast-header-icon {
    color:#ffa500 !important;
}