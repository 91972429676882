.rounded-input-dealer, .rounded-input-dealer .MuiOutlinedInput-root{
    border-radius: 25px !important;
     /* min-width: 200px !important; */
    
}

.rounded-button-dealer{
    background-color: #1a9fe0 !important;
    border-radius: 25px !important;
    text-transform: capitalize !important;
    font-size: 0.75rem !important;
    font-weight: 500 !important;
    font-family: Nunito !important;
    color:#f2f2f2 !important;
}
.rounded-button-dealer:disabled{
    background-color: #1a9fe0 !important;
    opacity: 0.5;
}
.height-500{
    min-height: 500px !important;
}
.dealer-table-title{
    color: #133F8A;
    opacity: 1;
    font-size: 0.80rem !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
}
.dealer-table-title-desc{
    color: #666666;
    opacity: 1;
    font-size: 0.70rem !important;
    font-weight: 600 !important;
    font-family: Nunito !important;
}
.dealer-table-content{
    color: #666666;
    opacity: 1;
    font-size: 0.75rem !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
}
.dealer-table-subcontent{
    color: #666666;
    opacity: 1;
    font-size: 0.65rem !important;
    font-weight: 600 !important;
    font-family: Nunito !important;
}
.dealer-company-name{
    color: #1A9FE0 !important;
    opacity: 1;
}
.dealer-grid-logo{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.rounded-button-dealer-cancel{
    background-color: #EAEAEA !important;
    opacity: 1;
    border-radius: 25px !important;
    text-transform: capitalize !important;
    font-size: 0.75rem !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
    color: #666666 !important;
}

.info-text{
    color: #666666;
    opacity: 1;
    font-size: 0.60rem !important;
    font-weight: 600 !important;
    font-family: Nunito !important;
}

.width-160{
    max-width: 160px !important;
}

.file-name{
    color: #1A9FE0;
    opacity: 1;
    display: inline-block;
    width: 140px;
    font-size: 0.70rem !important;
    font-weight: 600 !important;
    font-family: Nunito !important;
}
.file-close-color{
    color: #1A9FE0;
}
.cursor-pointer{
    cursor: pointer;
}

.brand-chip{
    background-color: #666666 !important;
    opacity: 1 !important;
    color: #fff !important;
}
.brand-limit-chip{
    background-color: #F1F1F1 !important;
    opacity: 1 !important;
    color: #1A9FE0 !important;
}
.uploaded-docs{
    color: #1A9FE0 !important;
    font-size: 0.75rem;
    font-weight: 700;
    font-family: Nunito !important;
    text-decoration: underline !important;
    cursor: pointer;
}
.user-details-bg{
    background-color: #f2f2f2;  
}
.valid-user-id{
    color: #1A9FE0 !important;
}
.box-shadow-view{
   scroll-behavior: auto;
  box-shadow: rgba(0,0,0.2,0.2)3px  3px 8px;
}
.font-color-dealer{
    color:#fff;
    font-size: 0.8125rem !important;
    font-family: Nunito !important;
}
.boxshadow-icon{
    background:#ffff !important;
    border-radius:26px !important;
    box-shadow:rgba(0,0,0,0.24) 0px 3px 8px !important
}
/* .font-dropdown{
    color:#1A2027 !important;
    font-weight: none !important;
    font-family:Nunito !important
} */