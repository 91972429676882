@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;700&display=swap');
/*common for all*/
.ln-0{line-height: 0}
.text-black{color: #000000}
.bg-black-75{background-color: rgba(0,0,0,0.75)}
.rounded-10{border-radius: 10px}
.bg-eee{background-color: #EFEFEF}
.bg-333{background-color: #333333}
.z-1{z-index: 1}
.t-0{top:0}

/*trade point (tp) new css */
.poppins-700{font-family: 'Poppins', sans-serif;font-weight: 700}
.tp-btn{padding: .5rem 2rem;border-width: 2px;font-weight: 600;border-radius: 10px}

.navbar.tp{top: 0;z-index: 999;box-shadow: 0 0.15rem 0.5rem 0 rgb(0, 0, 0, 0.05);}
.navbar.tp li.nav-item a{color: #888888;font-size: 1rem;font-weight: 600}
.navbar.tp li.nav-item.active a{color: #0D0D0D;}
.navbar.tp .collapsed {display: block!important;}

.site-content{margin-top: 79px;}
.container.xl{padding-left: 1.5rem;padding-right: 1.5rem;}

.carousel-indicators li{background-color: #999;opacity: 1;height: 12px;width: 12px;border-radius: 100px;border: none}
.carousel-indicators li.active{background-color: #1A9FE0;opacity: 1}
.slider-action{background-color: #EDEFF6;z-index: 1;position: relative;}
.carousel-inner{background-color: #F8F9FD}
.carousel-inner .carousel-item{height: auto;z-index: 1;}
.carousel-inner .carousel-item img{bottom: 0px;right: 0;}
.carousel-inner::before {content: " ";width: 100%;height: 1.5rem;background-color: #EDEFF6;position: absolute;bottom: 0}
.carousel-inner::after {content: " ";width: 40%;height: 3.75rem;background-color: #EDEFF6;position: absolute;bottom: 1.5rem;right: 0;z-index: 0;border-radius:10rem 0px 0px 0px }
.carousel-inner h1{font-size: 1.5rem}


.section{padding: 4.5rem 0}
.bg-register{background: url("./assets/landingpage/images/bg-register.png") no-repeat #000 center;background-size: cover}
.footer-menu li{border-right: 2px solid #fff;margin-right: 1rem;padding-right: 1rem}
.footer-menu li:last-child{border-right: none;margin-right: 0;padding-right: 0}

.scroll-top{bottom: 16px;right: 16px;z-index: 99;width: 42px;transition: all 0.1s ease-out 0s;border: 1px solid rgba(255,255,255,.3)}
.scroll-top .text{right: -100px}
.scroll-top:hover .text{right:16px}
.scroll-top:hover{width: 154px}

@media (min-width: 576px){
.tp-btn{padding: .6rem 2.75rem;}
.carousel-inner h1{font-size: 2.5rem}
.line-bg{background:url("./assets/landingpage/images/line-graphic.png") no-repeat right 0;}
.site-content{margin-top: 90px;}
}


@media (min-width: 992px){
.navbar.tp .navbar-nav .nav-link {padding-right: 1rem;padding-left: 1rem;}
.navbar.tp .navbar-toggler {display: none !important;}
.carousel-inner .carousel-item{height: 428px;background: url("./assets/landingpage/images/bg-logo.png") no-repeat center}
.position-lg-absolute{position: absolute}
.px-lg-10{padding-left:10rem ;padding-right: 10rem}
	
}
@media (min-width: 1200px){
.carousel-inner .carousel-item{height: 528px;}
}

	
@media (min-width: 1400px){
.container.xl{max-width: 1380px}
}


a{color: #1A9FE0;}
a:hover {color: #1A9FE0;}
.btn-primary { background-color: #1A9FE0; border-color: #1A9FE0;}
.btn-primary:hover {background-color: #1A9FE0;border-color: #1A9FE0;}
.btn-primary:focus, .btn-primary.focus {background-color: #1A9FE0;border-color: #1A9FE0;box-shadow: 0 0 0 0.2rem rgba(26, 159, 224, 0.5);}
.btn-primary.disabled, .btn-primary:disabled { background-color: #1A9FE0;border-color: #1A9FE0;}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {box-shadow: 0 0 0 0.2rem rgba(26, 159, 224, 0.5);}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {background-color: #1A9FE0;border-color: #0057b6;}
.btn-outline-primary {color: #1A9FE0;border-color: #1A9FE0;}
.btn-outline-primary:hover {background-color: #1A9FE0;border-color: #1A9FE0;}
.btn-outline-primary:focus, .btn-outline-primary.focus {box-shadow: 0 0 0 0.2rem rgba(26, 159, 224, 0.5);}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {color: #1A9FE0;background-color: transparent;}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {background-color: #1A9FE0;border-color: #1A9FE0;}
.btn-link {color: #1A9FE0;}
.btn-link:hover {color: #1A9FE0;}
.dropdown-item.active, .dropdown-item:active {background-color: #1A9FE0;}
.custom-control-input:checked ~ .custom-control-label::before {border-color: #1A9FE0;background-color: #1A9FE0;}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before { border-color: #1A9FE0; background-color: #1A9FE0;}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before { background-color: rgba(26, 159, 224, 0.5);}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {background-color: rgba(26, 159, 224, 0.5);}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {background-color: rgba(26, 159, 224, 0.5);}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {border-color: #b2bdfd;}
.custom-range::-webkit-slider-thumb { background-color: #1A9FE0;}
.custom-range::-moz-range-thumb {background-color: #1A9FE0;}
.custom-range::-ms-thumb { background-color: #1A9FE0;}
.custom-range::-ms-thumb:active {background-color: #b2bdfd;}
.custom-range::-moz-range-thumb:active {background-color: #b2bdfd;}
.custom-range::-webkit-slider-thumb:active {background-color: #b2bdfd;}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {background-color: #b2bdfd;border-color: #b2bdfd;}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {background-color: #1A9FE0;}
.page-link {color: #1A9FE0; }
.page-link:hover {color: #1A9FE0;}
.page-link:focus {box-shadow: 0 0 0 0.2rem rgba(26, 159, 224, 0.25);}
.page-item.active .page-link {background-color: #1A9FE0;border-color: #1A9FE0;}
.badge-primary {background-color: #1A9FE0;}
.progress-bar {background-color: #1A9FE0;}
.list-group-item.active {background-color: #1A9FE0;border-color: #1A9FE0;}
.bg-primary {background-color: #1A9FE0 !important;}
a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus {background-color: #1A9FE0 !important;}
.border-primary {border-color: #1A9FE0 !important;}
.text-primary {color: #1A9FE0 !important;}
a.badge-primary:hover, a.badge-primary:focus { background-color: #1A9FE0;}
a.text-primary:hover, a.text-primary:focus {color: #1A9FE0 !important;}
.form-control:focus{    border-color: #b2bdfd;    box-shadow: 0 0 0 0.2rem rgba(26, 159, 224, 0.25);}



